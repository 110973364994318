<template>
  <div class="product_detail">
    <div class="head">
      <p class="detailTit">{{detailData.parentProductName}}</p>
      <p class="rate">产品业绩比较基准</p>
      <p class="num">
            <!-- <em>8.4%~8.70%</em>
            <span>+0.1%</span> -->
        <template v-if="detailData.minStairRate!=null">
          <em>{{(parseFloat(detailData.profitRate*100)+parseFloat(detailData.minStairRate*100))/100 | numFilter}}</em>%
        </template>
        <template v-else>
          <em>{{detailData.profitRate | numFilter}}</em>%
        </template>

        <template v-if="detailData.stairRate!=null">
          <em>~{{(detailData.profitRate*100+detailData.stairRate*100)/100 | numFilter}}%</em>
        </template>
        <template v-if="detailData.floatRate!=0">
          <span>+{{detailData.floatRate}}%</span>
        </template>  
      </p>
      <p class="data"><span><b>{{detailData.productPeriod}}</b>天</span><span>起投<b>{{detailData.productMinimumSubscribe | formatMoney}}</b>元</span></p>
      <div class="progress">
        <div class="scale" :style="{width:scales+'%'}" >
          <i></i>
        </div>
        <span class="scales">已售{{scales}}%</span>
      </div>
      <div class="head_bottom">
        <span>产品规模<br>{{detailData.productAmount | formatMoney}}元</span>
        <span>剩余可预约金额<br>{{detailData.surplusMatchedAmount | formatMoney}}元</span>
      </div>
    </div>
    <div class="detail_con">
      <ul>
        <li>
          <span>产品成立日</span>
          <span>预约当日17:00之前打款，T+1成立</span>
        </li>
        <li>
          <span>预计到期日</span>
          <span>{{detailData.productDueDate | formateDate}}</span>
        </li>
        <li class="stairStrs">
          <span>业绩比较基准</span>
          <p class="stairStrsCon"></p>
        </li>
        <li>
          <span>分配方式</span>
          <span>{{detailData.cashStyleTitle}}</span>
        </li> 
      </ul>
    </div>
    <div class="agreement">
      <h3>产品资料</h3>
      <ul>
        <li @click="downLoadFun(1)">
          <span>产品推荐材料</span>
          <i></i>
        </li>
        <li @click="downLoadFun(9)">
          <span>产品说明书</span>
          <i></i>
        </li>
      </ul>
    </div>
   <p class="tips">——借贷有风险 投资需谨慎——</p>
   <div class="detail_btn" @click="orderFun(1)">
    <span>预约</span>
   </div>

  </div >
</template>
<script>
import $ from 'jquery'
import { selectProductDetail,productFilePreview} from "../services/proApi";
import { Dialog } from 'vant';
  export default {
    data(){
      return{
        dialogVisible: false,
        isShowRiskPop:false,
        detailData:{ },
        riskLevel:'',
        productDetailBtn:'立即委托出借',
        schedule:0,
        borrowTimeType:"",
        isLoading:false,
        mobilePhone:"",
        dialogShow:false,
        dialogMsg:'',
        btnName:'',
        scales:'',
        stairStrs:'',
      }
    },
    created: function () {
      //初始化数据    
      var _this = this;
     var productId=_this.$route.query.productId;
     var terminal=_this.$route.query.terminal;
      _this.selectProductDetail(productId);
     },
    methods: {
      async selectProductDetail(productId){
        const _this=this;
        const datas =await selectProductDetail({productId:productId});
        if (datas.code === 1) {
          var scales=datas.data.scales;
          _this.scales=scales;
          _this.detailData = datas.data;
          if(datas.data.stairRate==null){
            $(".num span").css("right","25%")
          }
          //判断业绩比较基准
          let stairStrs=datas.data.stairStrs;
          console.log(stairStrs)
          var html=""; 
          if(stairStrs!=undefined){
            for(var i=0;i<stairStrs.length;i++){
                html+="<span>"+stairStrs[i]+"</span>";
            }
          }else{
            if(datas.data.floatRate!=0){
              html=datas.data.profitRate+"%"+"+"+datas.data.floatRate+"%"
            }else{
              html=datas.data.profitRate+"%"
            }
            
          }
          $(".stairStrsCon").append(html);
        }  
      },
       //资料下载
      async downLoadFun(fileType){
        let _this=this;
        var productId=_this.$route.query.productId;
  
       const datas =await productFilePreview({productId:productId,fileType:fileType});
        if (datas.code === 1) {
          var href='/static/webPdf/web/viewer.html?file='+encodeURIComponent(datas.data);
          location.href=href;
        }else{
            Dialog.alert({
                title: '温馨提示',
                confirmButtonText:'知道了',
                message:'没有配置相关材料',
              })
              .then(() => {
                  // on confirm
              })
        }
         
      },
      orderFun(){
        var obj=this;
        var productId=obj.$route.query.productId;
        var channelFlag=obj.$route.query.channelFlag//渠道
        this.$router.push({ path:"order",query:{productId:productId,channelFlag:channelFlag}});  
       },
       riskPopClose(){
        this.isShowRiskPop=false;
       },
       dialogFun(){
          if(this.btnName=='知道了'){
            this.dialogShow=false;
          }
       }
    },
    filters:{
        formatMoney:function(money) {
            money = money > 9999 ? money/10000 + "万" : money;
             return  money
         },
        formateDate:function(now){
          var now = parseInt(now);
          var d = new Date(now);
          return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()
        },
        numFilter:function(value) {
            let realVal = ''
            if (!isNaN(value) && value!== '') {
              // 截取当前数据到小数点后两位
              realVal = parseFloat(value).toFixed(2)
            } else {
              realVal = '--'
            }
            return realVal
          }   
    }, 

  };
</script>

<style lang="scss"> 
.product_detail{
  color: #fff;
  .head{
    background: linear-gradient(0deg, #356BFF, #0398FF);
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem 0;
    .detailTit{font-size: 1.1rem;}
  }
  .rate{padding: 1rem 0;font-size: 0.8rem;}
  .num{
    padding-bottom: 1rem;position: relative;
    em{font-size: 2rem;}
    span{
      font-size: .6rem;
      color: #0062FF;
      background: url(../assets/img/bubble_lv.png) no-repeat;
      width: 2.5rem;
      height: 1.2rem;
      display: block;
      line-height: 1rem;
      background-size: contain;
      position: absolute;
      right:9%;
      top: -1rem;
    }
  }
  .data{
    font-size: 0.8rem;
    span{
      padding:0 0.5rem;
      b{font-size: 1rem}
    }
    span:first-child{border-right: 0.1rem solid #fff;}
  }
  .progress{position: relative;margin-top: 1.5rem;border-top: 0.02rem double #58ACFF;}
  .scale{
    position: relative;
    height: 0.1rem;
    background: linear-gradient(to right, #356BFF, #fdfdfd);
    margin-top: -0.1rem;
    i{
      width: .6rem;
      height: .6rem;
      display: block;
      top: -0.2rem;
      right: -0.2rem;
      position: absolute;
      background: url(../assets/img/spot.png) no-repeat;
      background-size: contain;}
  }
  .scales{
    position: absolute;
    right: 0.5rem;
    top: -2.8rem;
    font-size: 0.8rem;
  }
  .head_bottom{
    display: flex;justify-content: space-between;font-size: 0.8rem;padding: 1rem 0.5rem 0 0.5rem;
     span{text-align: left;line-height: 1.1rem;}
     span:last-child{text-align: right;}
  }


} 
.detail_con{
  background: #FFF;margin-top: 0.5rem;color:#333;
  li{
    display: flex;justify-content: space-between;font-size: 0.9rem;
    border-bottom: 0.1rem solid #dfdfdf;margin:0 1rem;height:3rem;line-height:3rem;
    span{text-align: left;}
    span:last-child{text-align: right;color:#999;}
  }
  li:last-child{border:none;}
  .stairStrs{
    height: inherit;line-height: initial;padding: 0.3rem 0;
    .stairStrsCon{
      color:#999;
      span{display: block;text-align: right;}
    }
    
  }
}
.agreement{
  background: #FFF;margin-top: 0.5rem;color:#333;
  h3{font-size: 1rem; display: flex;justify-content: space-between;
    border-bottom: 0.1rem solid #dfdfdf;margin:0 1rem;height: 3rem;line-height: 3rem;}
  li{
    display: flex;justify-content: space-between;
    border-bottom: 0.1rem solid #dfdfdf;margin:0 1rem;height: 3rem;line-height:3rem;
    i{
      background-size: contain;width: 0.5rem;height: 0.5rem;margin-top: 0.7rem;
    }
  }
}
.tips{color:#999;text-align: center;font-size: 0.6rem;padding-top: 1rem;padding-bottom: 4rem;}
.detail_btn{
  background: -webkit-linear-gradient(left,#0398FF,#356BFF);color:#fff;
  height:3rem;line-height:3rem;text-align: center;position: fixed;bottom:0;
  width: 100%;letter-spacing:0.5rem;font-size:1.1rem;
  }
</style>